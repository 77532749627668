import { Container, Col, Row } from "react-grid-system";
import NavBar from "./NavBar";
import { Button } from "@mui/material";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { ref, set, update } from "firebase/database";
import { database } from "../firebase";
import emailjs from 'emailjs-com';
import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import ButtonNewCom from '@mui/material/Button';
import { Typography } from '@mui/material';
import "./Bfutton.css";


const SERVICE_ID = "service_ahfduhi";
const TEMPLATE_ID = "template_mzi14pb";
const PUBLIC_KEY = "1nt9anrE5pKavGRCG";

function PcbDesignPage_2() {
    const [open3, setOpen3] = React.useState(false);

    const handleClickOpen3 = () => {
        setOpen3(true);
    };

    const handleProceed = () => {
        setOpen3(false);
        setTimeout(() => {
            sessionStorage.clear();
            window.location.href = 'https://iot-system-design-tool.protonest.co/';
        }, 100);
    };

    const handleCancelAlert = () => {
        setOpen3(false);
    };

    const handleBackClick = () => {
        navigate(-1); // This navigates back to the previous page
    };
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setTimeout(() => {
            sessionStorage.clear();
            window.location.href = 'https://iot-system-design-tool.protonest.co/';
        }, 100);
    };

    const [finishButtonText,setFinishButtonText]=useState('Next');

    useEffect(()=>{
        const enclosureSelected = JSON.parse(sessionStorage.getItem('iotchoices_1')).enclosure;
        const pcbSelected = JSON.parse(sessionStorage.getItem('iotchoices_1')).pcb;

        const componentSelected = JSON.parse(sessionStorage.getItem('componentdetails')).component;
        const personDetailsSaved = JSON.parse(sessionStorage.getItem('personaldetails'));
        if (componentSelected === 'Complete System') {

            const iot1 = JSON.parse(sessionStorage.getItem('iotdetails_1'));
            const iot2 = JSON.parse(sessionStorage.getItem('savedprotocoldetails'));
            const iot3 = (sessionStorage.getItem('storagedetails')) ? JSON.parse(sessionStorage.getItem('storagedetails')) : "Same storage for all";
            const iot4 = JSON.parse(sessionStorage.getItem('iotchoices_1'));
            const iot5 = (sessionStorage.getItem('iotchoices_2')) ? JSON.parse(sessionStorage.getItem('iotchoices_2')) : "Same interface settings for all";

           if (JSON.parse(sessionStorage.getItem('iotchoices_2')) && JSON.parse(sessionStorage.getItem('iotchoices_2')).interface && JSON.parse(sessionStorage.getItem('iotchoices_2')).interface !== 'No User interface') {
                const interfaceSelected = JSON.parse(sessionStorage.getItem('iotchoices_2')).interface;
                if (interfaceSelected === 'Mobile App') {
                   
                } else if (interfaceSelected === 'Web App') {
                   
                } else if (interfaceSelected === 'Touch Display') {
                    
                } else if (interfaceSelected === 'Non-Touch Display') {
                    
                }
            } else {
                const permanentdetails_1 = JSON.parse(sessionStorage.getItem('permanentdetails'));
                if((permanentdetails_1.numberOfIotDevices) - 1 === 0){
                    setFinishButtonText('Finish');
                }
            }

        } else {
            const interfaceSelected = JSON.parse(sessionStorage.getItem('iotchoices_2')).interface;
            const iot1 = JSON.parse(sessionStorage.getItem('iotdetails_1'));
            const iot2 = JSON.parse(sessionStorage.getItem('savedprotocoldetails'));
            const iot3 = JSON.parse(sessionStorage.getItem('storagedetails'));
            const iot4 = JSON.parse(sessionStorage.getItem('iotchoices_1'));
            const iot5 = JSON.parse(sessionStorage.getItem('iotchoices_2'));

            if (interfaceSelected === 'Mobile App') {
               
            } else if (interfaceSelected === 'Web App') {
                
            } else if (interfaceSelected === 'Touch Display') {
               
            } else if (interfaceSelected === 'Non-Touch Display') {
               
            } else {
                setFinishButtonText("Finish");
            }

        }
    },[]);


    const sendEmail = (detailsSaved) => {
        const emailParams = {
            to_email: 'webquestionnaire108@gmail.com',
            finalIotObject: JSON.stringify(detailsSaved, null, 2) // Convert object to a readable string format
        };

        emailjs.send(
            SERVICE_ID,      // Replace with your EmailJS service ID
            TEMPLATE_ID,     // Replace with your EmailJS template ID
            emailParams,            // Parameters to send (like finalIotObject)
            PUBLIC_KEY          // Replace with your EmailJS user ID
        )
            .then((response) => {
                console.log('Email sent successfully!', response.status, response.text);
            })
            .catch((error) => {
                console.error('Failed to send email:', error);
            });
    };

    const [changesAdded, setChangesAdded] = useState(false);
    const [designDetails, setDesignDetails] = useState(() => {
        const savedDetails = sessionStorage.getItem('pcbdetailssaved');
        return savedDetails ? JSON.parse(savedDetails) : {
            size_Pcb: '',
            layer_number: '',
            inBuilt_details: '',
            special_req: 'Flexible PCBs',
            power_req: '',
            ic_prefer: '',
            connector_prefer: '',
            indicators_req: '',
            vendors_prefer: '',
            placement_prefer: '',
            cost_constraint: '',
            output_req: 'Gerber files',
            assembly_req: '',
            testing_req: '',
            certifications: '',
            other_details: '',
            file_formats: '',
        }
    });

    const navigate = useNavigate();
    useEffect(() => {
        sessionStorage.setItem('pcbdetailssaved', JSON.stringify(designDetails));
    }, [designDetails])

    const handleCancelClick = () => {
        handleClickOpen3();
    };


    function handleClickNext() {
        console.log(JSON.parse(sessionStorage.getItem('pcbdetailssaved')));



        const componentSelected = JSON.parse(sessionStorage.getItem('componentdetails')).component;
        const personDetailsSaved = JSON.parse(sessionStorage.getItem('personaldetails'));

        if (componentSelected === 'Complete System') {
            const iot1 = JSON.parse(sessionStorage.getItem('iotdetails_1'));
            const iot2 = JSON.parse(sessionStorage.getItem('savedprotocoldetails'));
            const iot3 = (sessionStorage.getItem('storagedetails')) ? JSON.parse(sessionStorage.getItem('storagedetails')) : "Same storage for all";
            const iot4 = JSON.parse(sessionStorage.getItem('iotchoices_1'));
            const iot5 = (sessionStorage.getItem('iotchoices_2')) ? JSON.parse(sessionStorage.getItem('iotchoices_2')) : "Same interface settings for all";
            const iot6 = JSON.parse(sessionStorage.getItem('iototherdetails'));
            let iot7 = sessionStorage.getItem('batterydetails');
            let iot8 = sessionStorage.getItem('enclosuredesigndetails');

            if (JSON.parse(sessionStorage.getItem('iotchoices_2')) && JSON.parse(sessionStorage.getItem('iotchoices_2')).interface && JSON.parse(sessionStorage.getItem('iotchoices_2')).interface !== 'No User interface') {
                const interfaceSelected = JSON.parse(sessionStorage.getItem('iotchoices_2')).interface;
                if (interfaceSelected === 'Mobile App') {
                    navigate('/mobileapppage');
                } else if (interfaceSelected === 'Web App') {
                    navigate('/webapplicationpage');
                } else if (interfaceSelected === 'Touch Display') {
                    navigate('/touchdisplaypage');
                } else if (interfaceSelected === 'Non-Touch Display') {
                    navigate('/nontouchdisplaypage');
                }
            } else {
                let finalIotObject = {
                    iot_page_1: iot1,
                    iot_page_2: iot2,
                    iot_page_3: iot3,
                    iot_page_4: iot4,
                    iot_page_5: iot5,
                    ML_Energyconservation_Battery: iot6,
                    pcb_details: designDetails
                };

                if (iot7) {
                    iot7 = JSON.parse(iot7)
                    finalIotObject = {
                        ...finalIotObject,
                        battery_details: iot7
                    };
                }

                if (iot8) {
                    iot8 = JSON.parse(iot8)
                    finalIotObject = {
                        ...finalIotObject,
                        enclosure_details: iot8
                    };
                }

                let permanentdetails = JSON.parse(sessionStorage.getItem('permanentdetails'));
                const numberOfIotDevicesTotal = JSON.parse(sessionStorage.getItem('overviewcompletedetails')).numberOfIotDevices;
                const iotDeviceKeyName = `iot_device_${permanentdetails.numberOfIotDevices}`
                let detailsSaved = {};

                let dateNow = 'date';
                if (permanentdetails.numberOfIotDevices === numberOfIotDevicesTotal) {
                    dateNow = Date.now();

                    sessionStorage.setItem('permanentdetails', JSON.stringify(
                        {
                            ...permanentdetails,
                            dateNow: dateNow
                        }
                    ));
                    permanentdetails = JSON.parse(sessionStorage.getItem('permanentdetails'));
                    detailsSaved = {
                        personalDetails: personDetailsSaved,
                        component: componentSelected,
                        [iotDeviceKeyName]: finalIotObject
                    }
                    const dbRef = ref(database, `${dateNow}`);
                    set(dbRef, detailsSaved).then(() => {
                        console.log("Saved to database");
                        if (numberOfIotDevicesTotal === '1' || permanentdetails.numberOfIotDevices === '1') {
                            sendEmail(detailsSaved);
                            sessionStorage.clear();
                            setChangesAdded(true);
                            handleClickOpen();

                            // setTimeout(() => {
                            //     sessionStorage.clear();
                            //     window.location.href = 'https://protonest.co/';
                            // }, 100);
                        } else {
                            const permanentdetails2 = JSON.parse(sessionStorage.getItem('permanentdetails'));
                            sessionStorage.setItem('permanentdetails',
                                JSON.stringify(
                                    {
                                        ...permanentdetails2,
                                        numberOfIotDevices: (permanentdetails2.numberOfIotDevices) - 1,
                                        iotname: (permanentdetails.iotname) + 1
                                    }
                                )
                            );

                            sessionStorage.removeItem('iotdetails_1');
                            sessionStorage.removeItem('savedprotocoldetails');
                            sessionStorage.removeItem('storagedetails');
                            sessionStorage.removeItem('iotchoices_1');
                            sessionStorage.removeItem('iotchoices_2');
                            sessionStorage.removeItem('iototherdetails');
                            sessionStorage.removeItem('batterydetails');
                            sessionStorage.removeItem('enclosuredesigndetails');
                            sessionStorage.removeItem('pcbdetailssaved');
                            sessionStorage.removeItem('uiselected');
                            navigate('/iotdevicesection_1');
                        }

                    }).catch((error) => {
                        console.error("Error saving in database: ", error);
                    })

                } else {
                    permanentdetails = JSON.parse(sessionStorage.getItem('permanentdetails'));
                    dateNow = permanentdetails.dateNow;
                    detailsSaved = {
                        [iotDeviceKeyName]: finalIotObject
                    }
                    const dbRef = ref(database, `${dateNow}`);
                    update(dbRef, detailsSaved).then(() => {
                        console.log("Saved to database");
                        if (numberOfIotDevicesTotal === 1 || permanentdetails.numberOfIotDevices === 1) {
                            sendEmail(detailsSaved);
                            sessionStorage.clear();
                            setChangesAdded(true);
                            handleClickOpen();

                            // setTimeout(() => {
                            //     sessionStorage.clear();
                            //     window.location.href = 'https://protonest.co/';
                            // }, 100);
                        } else {
                            sessionStorage.setItem('permanentdetails',
                                JSON.stringify(
                                    {
                                        ...permanentdetails,
                                        numberOfIotDevices: (permanentdetails.numberOfIotDevices) - 1,
                                        iotname: (permanentdetails.iotname) + 1
                                    }
                                )
                            );

                            sessionStorage.removeItem('iotdetails_1');
                            sessionStorage.removeItem('savedprotocoldetails');
                            sessionStorage.removeItem('storagedetails');
                            sessionStorage.removeItem('iotchoices_1');
                            sessionStorage.removeItem('iotchoices_2');
                            sessionStorage.removeItem('iototherdetails');
                            sessionStorage.removeItem('batterydetails');
                            sessionStorage.removeItem('enclosuredesigndetails');
                            sessionStorage.removeItem('pcbdetailssaved');
                            sessionStorage.removeItem('uiselected');
                            navigate('/iotdevicesection_1');
                        }

                    }).catch((error) => {
                        console.error("Error saving in database: ", error);
                    })

                }



            }
        } else if (componentSelected === 'IOT device') {

            const iot1 = JSON.parse(sessionStorage.getItem('iotdetails_1'));
            const iot2 = JSON.parse(sessionStorage.getItem('savedprotocoldetails'));
            const iot3 = JSON.parse(sessionStorage.getItem('storagedetails'));
            const iot4 = JSON.parse(sessionStorage.getItem('iotchoices_1'));
            const iot5 = JSON.parse(sessionStorage.getItem('iotchoices_2'));
            const iot6 = JSON.parse(sessionStorage.getItem('iototherdetails'));
            let iot7 = sessionStorage.getItem('batterydetails');
            let iot8 = sessionStorage.getItem('enclosuredesigndetails');
            const interfaceSelected = JSON.parse(sessionStorage.getItem('iotchoices_2')).interface;

            if (interfaceSelected === 'Mobile App') {
                navigate('/mobileapppage');
            } else if (interfaceSelected === 'Web App') {
                navigate('/webapplicationpage');
            } else if (interfaceSelected === 'Touch Display') {
                navigate('/touchdisplaypage');
            } else if (interfaceSelected === 'Non-Touch Display') {
                navigate('/nontouchdisplaypage');
            } else {

                let finalIotObject = {
                    iot_page_1: iot1,
                    iot_page_2: iot2,
                    iot_page_3: iot3,
                    iot_page_4: iot4,
                    iot_page_5: iot5,
                    ML_Energyconservation_Battery: iot6,
                    pcb_details: designDetails
                };

                if (iot7) {
                    iot7 = JSON.parse(iot7)
                    finalIotObject = {
                        ...finalIotObject,
                        battery_details: iot7
                    };
                }

                if (iot8) {
                    iot8 = JSON.parse(iot8)
                    finalIotObject = {
                        ...finalIotObject,
                        enclosure_details: iot8
                    };
                }

                const detailsSaved = {
                    personalDetails: personDetailsSaved,
                    component: componentSelected,
                    details: finalIotObject
                }
                const dbRef = ref(database, `${Date.now()}`);
                set(dbRef, detailsSaved).then(() => {
                    console.log("Saved to database");
                }).catch((error) => {
                    console.error("Error saving in database: ", error);
                })
                sendEmail(detailsSaved);
                sessionStorage.clear();
                setChangesAdded(true);
                handleClickOpen();

                // setTimeout(() => {
                //     sessionStorage.clear();
                //     window.location.href = 'https://protonest.co/';
                // }, 100);
            }

        } else if (componentSelected === 'PCB design') {
            const detailsSaved = {
                personalDetails: personDetailsSaved,
                component: componentSelected,
                pcb_details: designDetails
            }
            const dbRef = ref(database, `${Date.now()}`);
            set(dbRef, detailsSaved).then(() => {
                console.log("Saved to database");
            }).catch((error) => {
                console.error("Error saving in database: ", error);
            })
            sendEmail(detailsSaved);
            sessionStorage.clear();
            setChangesAdded(true);
            handleClickOpen();

            // setTimeout(() => {
            //     sessionStorage.clear();
            //     window.location.href = 'https://protonest.co/';
            // }, 100);
        }
    }

    useEffect(() => {
        const handleBeforeUnload = (event) => {
            // Clear sessionStorage when the user leaves the site
            sessionStorage.clear();
            // if (!changesAdded) {
            //     // Optionally add a warning for unsaved changes
            //     event.preventDefault(); // For most browsers
            //     event.returnValue = '';  // For Chrome
            // }
        };

        window.addEventListener('beforeunload', handleBeforeUnload);

        // Cleanup event listener on component unmount
        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, [changesAdded]);


    useEffect(() => {
        const savedDetails = sessionStorage.getItem('personaldetails');
        if (!savedDetails) {
            sessionStorage.clear();
            navigate('/');
        }
    }, []);

    return (
        <Container style={{ marginBottom: "0" }}>
            <NavBar pageNo={5} />
            <div className="first-page-row" style={{ backgroundColor: "#ffffff" }}>
                <Row
                    className="first-row"
                    style={{
                        paddingTop: '1rem',

                        paddingLeft: "2rem",
                        paddingRight: "2rem",

                        backgroundColor: "#ffffff",
                    }}
                >
                    <Col sm={12} md={6} >

                        <Row>
                            <Col sm={12} md={12} style={{ height: '100%', margin: '0 0 0 0', padding: '0 0 0 0' }}>
                                <div style={{ display: 'flex' }}>
                                    <p className="heading" style={{ fontFamily: 'poppins', fontWeight: '700', color: "rgba(71, 85, 105, 1)" }}>PCB Design (Part 2)</p>
                                </div>
                            </Col>
                        </Row>
                        <Row >
                            <Col sm={12} md={12} style={{ height: '100%', margin: '0' }}>

                                <div >
                                    <label
                                        className="text"
                                        style={{
                                            fontWeight: '500',
                                            marginBottom: "0.5rem",

                                            fontFamily: "poppins",
                                            color: "rgba(71, 85, 105, 1)",
                                        }}
                                    >
                                        Mention preferred vendors or sources for components
                                    </label>
                                    <br />
                                    <textarea
                                        className="textBox5"
                                        type="text"
                                        name="vendors_prefer"
                                        placeholder="Enter preferences"
                                        value={designDetails.vendors_prefer}
                                        style={{
                                            border: '1px solid rgba(203, 213, 225, 0.7)',
                                            fontFamily: 'poppins',

                                            padding: '3px 3px 3px 15px',
                                            marginTop: "0.3rem",
                                            maxLines: '20',
                                            borderRadius: "5px",

                                            width: "90%",
                                            resize: 'none'
                                        }}
                                        onChange={(e) => (setDesignDetails((prev) => { return { ...prev, vendors_prefer: e.target.value } }))}
                                    />
                                </div>

                            </Col>
                        </Row>
                        <Row >
                            <Col sm={12} md={12} style={{ height: '100%', margin: '0' }}>

                                <div style={{ marginTop: '1rem' }} >
                                    <label
                                        className="text"
                                        style={{
                                            fontWeight: '500',
                                            marginBottom: "0.5rem",

                                            fontFamily: "poppins",
                                            color: "rgba(71, 85, 105, 1)",
                                        }}
                                    >
                                        Provide details on the preferred placement of I/O connector ports.
                                    </label>
                                    <br />
                                    <textarea
                                        type="text"
                                        className="textBox5"
                                        name="placement_prefer"
                                        placeholder="Enter placement preferences"
                                        value={designDetails.placement_prefer}
                                        style={{
                                            border: '1px solid rgba(203, 213, 225, 0.7)',
                                            fontFamily: 'poppins',

                                            padding: '3px 3px 3px 15px',
                                            marginTop: "0.3rem",
                                            maxLines: '20',
                                            borderRadius: "5px",

                                            width: "90%",
                                            resize: 'none'
                                        }}
                                        onChange={(e) => (setDesignDetails((prev) => { return { ...prev, placement_prefer: e.target.value } }))}
                                    />
                                </div>

                            </Col>
                        </Row>
                        <Row >
                            <Col sm={12} md={12} style={{ height: '100%', margin: '0' }}>

                                <div style={{ marginTop: '1rem' }} >
                                    <label
                                        className="text"
                                        style={{
                                            fontWeight: '500',
                                            marginBottom: "0.5rem",

                                            fontFamily: "poppins",
                                            color: "rgba(71, 85, 105, 1)",
                                        }}
                                    >
                                        Mention any specific cost constraints for single PCB
                                    </label>
                                    <br />
                                    <textarea
                                        type="text"
                                        className="textBox5"
                                        name="cost_constraint"
                                        placeholder="Enter cost constraint"
                                        value={designDetails.cost_constraint}
                                        style={{
                                            border: '1px solid rgba(203, 213, 225, 0.7)',
                                            fontFamily: 'poppins',

                                            padding: '3px 3px 3px 15px',
                                            marginTop: "0.3rem",
                                            maxLines: '20',
                                            borderRadius: "5px",

                                            width: "90%",
                                            resize: 'none'
                                        }}
                                        onChange={(e) => (setDesignDetails((prev) => { return { ...prev, cost_constraint: e.target.value } }))}
                                    />
                                </div>

                            </Col>
                        </Row>
                        <Row >
                            <Col sm={12} md={12} style={{ height: '100%', margin: '0' }}>

                                <div style={{ marginTop: '1rem', paddingBottom: '5px' }} >
                                    <label
                                        className="text"
                                        style={{
                                            fontWeight: '500',
                                            marginBottom: "0.5rem",

                                            fontFamily: "poppins",
                                            color: "rgba(71, 85, 105, 1)",
                                        }}
                                    >
                                        Mention any special requirements for the assembly process
                                    </label>
                                    <br />
                                    <textarea
                                        type="text"
                                        name="assembly_req"
                                        className="textBox5"
                                        placeholder="Enter assembly requirements"
                                        value={designDetails.assembly_req}
                                        style={{
                                            border: '1px solid rgba(203, 213, 225, 0.7)',
                                            fontFamily: 'poppins',

                                            padding: '3px 3px 3px 15px',
                                            marginTop: "0.3rem",
                                            maxLines: '20',
                                            borderRadius: "5px",

                                            width: "90%",
                                            resize: 'none'
                                        }}
                                        onChange={(e) => (setDesignDetails((prev) => { return { ...prev, assembly_req: e.target.value } }))}
                                    />
                                </div>

                            </Col>
                        </Row>

                    </Col>
                    <Col sm={12} md={6} >
                        <Row style={{ marginTop: '1rem' }} >
                            <Col sm={12} md={12} style={{ height: '100%', margin: '0' }}>

                                <div style={{ marginTop: '1rem', marginBottom: '1rem' }} >
                                    <label
                                        className="text"
                                        style={{
                                            fontWeight: '500',
                                            marginBottom: "0.5rem",

                                            fontFamily: "poppins",
                                            color: "rgba(71, 85, 105, 1)",
                                        }}
                                    >
                                        Describe the required testing procedures
                                    </label>
                                    <br />
                                    <textarea
                                        className="textBox5"
                                        type="text"
                                        name="testing_req"
                                        value={designDetails.testing_req}
                                        placeholder="Enter testing requirements"
                                        style={{
                                            border: '1px solid rgba(203, 213, 225, 0.7)',
                                            fontFamily: 'poppins',

                                            padding: '3px 3px 3px 15px',
                                            marginTop: "0.3rem",
                                            maxLines: '20',
                                            borderRadius: "5px",

                                            width: "90%",
                                            resize: 'none'
                                        }}
                                        onChange={(e) => (setDesignDetails((prev) => { return { ...prev, testing_req: e.target.value } }))}
                                    />
                                </div>

                            </Col>
                        </Row>
                        <Row >
                            <Col sm={12} md={12} style={{ height: '100%', margin: '0' }}>

                                <div style={{ marginTop: '0.1rem', marginBottom: '1rem' }} >
                                    <label
                                        className="text"
                                        style={{
                                            fontWeight: '500',
                                            marginBottom: "0.5rem",

                                            fontFamily: "poppins",
                                            color: "rgba(71, 85, 105, 1)",
                                        }}
                                    >
                                        List any standards or certifications the PCB needs to meet.
                                    </label>
                                    <br />
                                    <textarea
                                        type="text"
                                        className="textBox5"
                                        name="certifications"
                                        value={designDetails.certifications}
                                        placeholder="Enter needed standards"
                                        style={{
                                            border: '1px solid rgba(203, 213, 225, 0.7)',
                                            fontFamily: 'poppins',

                                            padding: '3px 3px 3px 15px',
                                            marginTop: "0.3rem",
                                            maxLines: '20',
                                            borderRadius: "5px",

                                            width: "90%",
                                            resize: 'none'
                                        }}
                                        onChange={(e) => (setDesignDetails((prev) => { return { ...prev, certifications: e.target.value } }))}
                                    />
                                </div>

                            </Col>
                        </Row>
                        <Row >
                            <Col sm={12} md={12} style={{ height: '100%', margin: '0' }}>

                                <div style={{ marginTop: '0.1rem', marginBottom: '1rem' }} >
                                    <label
                                        className="text"
                                        style={{
                                            fontWeight: '500',
                                            marginBottom: "0.5rem",

                                            fontFamily: "poppins",
                                            color: "rgba(71, 85, 105, 1)",
                                        }}
                                    >
                                        Include any other relevant information or requirements not covered above.
                                    </label>
                                    <br />
                                    <textarea
                                        type="text"
                                        name="other_details"
                                        className="textBox5"
                                        placeholder="Enter other details"
                                        value={designDetails.other_details}
                                        style={{
                                            border: '1px solid rgba(203, 213, 225, 0.7)',
                                            fontFamily: 'poppins',

                                            padding: '3px 3px 3px 15px',
                                            marginTop: "0.3rem",
                                            maxLines: '20',
                                            borderRadius: "5px",

                                            width: "90%",
                                            resize: 'none'
                                        }}
                                        onChange={(e) => (setDesignDetails((prev) => { return { ...prev, other_details: e.target.value } }))}
                                    />
                                </div>

                            </Col>
                        </Row>
                        <Row >
                            <Col sm={12} md={12} style={{ height: '100%', margin: '0' }}>

                                <div style={{ marginTop: '0.1rem', marginBottom: '1rem' }} >
                                    <label
                                        className="text"
                                        style={{
                                            fontWeight: '500',
                                            marginBottom: "0.5rem",

                                            fontFamily: "poppins",
                                            color: "rgba(71, 85, 105, 1)",
                                        }}
                                    >
                                        Specify the preferred file formats
                                    </label>
                                    <br />
                                    <textarea
                                        type="text"
                                        name="file_formats"
                                        placeholder="Enter file formats"
                                        className="textBox5"
                                        value={designDetails.file_formats}
                                        style={{
                                            border: '1px solid rgba(203, 213, 225, 0.7)',
                                            fontFamily: 'poppins',

                                            padding: '3px 3px 3px 15px',
                                            marginTop: "0.3rem",
                                            maxLines: '20',
                                            borderRadius: "5px",

                                            width: "90%",
                                            resize: 'none'
                                        }}
                                        onChange={(e) => (setDesignDetails((prev) => { return { ...prev, file_formats: e.target.value } }))}
                                    />
                                </div>

                            </Col>
                        </Row>
                    </Col>
                </Row>
            </div>
            <br />
            <div >
                <Row>
                    <Col sm={12} md={12} className="button-container">
                    <Button className="text" onClick={handleCancelClick} style={{backgroundColor: 'rgba(71, 85, 105, 1)', borderRadius: '6px', color: 'white', minHeight: '40px', minWidth: '100px', marginRight: '20px', fontFamily: 'poppins', fontWeight: 'bold'}} > Cancel </Button>
                        <Button className="text" onClick={handleBackClick} style={{ backgroundColor: 'rgba(71, 85, 105, 1)', borderRadius: '6px', color: 'white', minHeight: '40px', minWidth: '100px', marginRight: '20px', fontFamily: 'poppins', fontWeight: 'bold' }}> Back </Button>
                        <Button className="text" onClick={handleClickNext} style={{ backgroundColor: 'rgba(156, 136, 255, 1)', borderRadius: '6px', color: 'white', minHeight: '40px', minWidth: '100px', fontFamily: 'poppins', fontWeight: 'bold' }}> {finishButtonText} </Button>
                    </Col>
                </Row>
            </div>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                maxWidth="sm"
                fullWidth
                PaperProps={{
                    style: {
                        borderRadius: '16px',
                        padding: '0.5rem',
                        background: 'linear-gradient(135deg, #9C88FF, #b3a5fa)',
                        color: 'white',
                    }
                }}
            >
                <DialogTitle id="alert-dialog-title" style={{ display: 'flex', alignItems: 'center' }}>

                    <Typography variant="h6" component="span" style={{ color: '#fff9c4' }}>
                        Success
                    </Typography>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText
                        id="alert-dialog-description"
                        style={{
                            color: 'white',
                            fontSize: '1.1rem',
                            fontFamily: 'poppins',
                            marginBottom: '1rem',
                        }}
                    >
                        Your form has been successfully submitted.
                        The Protonest team will reach out to you soon for further details.
                    </DialogContentText>
                </DialogContent>
                <DialogActions style={{ justifyContent: 'center' }}>
                    <ButtonNewCom
                        onClick={handleClose}
                        variant="contained"
                        style={{
                            backgroundColor: 'white',
                            color: '#9C88FF',
                            borderRadius: '16px',
                            padding: '0.5rem 1.5rem',
                            fontWeight: 'bold',
                        }}
                    >
                        Close
                    </ButtonNewCom>
                </DialogActions>
            </Dialog>
            <Dialog
                open={open3}
                onClose={handleCancelAlert}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                maxWidth="sm"
                fullWidth
                PaperProps={{
                    style: {
                        borderRadius: '16px',
                        padding: '0.5rem',
                        background: 'linear-gradient(135deg, #9C88FF, #b3a5fa)',
                        color: 'white',
                    }
                }}
            >
                <DialogTitle id="alert-dialog-title" style={{ display: 'flex', alignItems: 'center' }}>

                    <Typography variant="h6" component="span" style={{ color: '#fff9c4' }}>
                        Confirm
                    </Typography>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText
                        id="alert-dialog-description"
                        style={{
                            color: 'white',
                            fontSize: '1.1rem',
                            fontFamily: 'poppins',
                            marginBottom: '1rem',
                        }}
                    >
                        Are you sure you want to cancel? All your data will be removed if you proceed.
                    </DialogContentText>
                </DialogContent>
                <DialogActions style={{ justifyContent: 'center' }}>
                    <ButtonNewCom
                        onClick={handleCancelAlert}
                        variant="contained"
                        style={{
                            backgroundColor: 'white',
                            color: '#9C88FF',
                            borderRadius: '16px',
                            padding: '0.5rem 1.5rem',
                            fontWeight: 'bold',
                            marginRight: '10px', // add some space between buttons
                        }}
                    >
                        Cancel
                    </ButtonNewCom>
                    <ButtonNewCom
                        onClick={handleProceed}
                        variant="contained"
                        style={{
                            backgroundColor: 'white',
                            color: '#9C88FF',
                            borderRadius: '16px',
                            padding: '0.5rem 1.5rem',
                            fontWeight: 'bold',
                        }}
                    >
                        Proceed
                    </ButtonNewCom>
                </DialogActions>
            </Dialog>
        </Container>
    );
}

export default PcbDesignPage_2;

