import { Container, Col, Row } from "react-grid-system";
import NavBar from "./NavBar";
import { Button } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import uploadSvg from '../assets/upload.svg';
import fileImg from '../assets/file.svg'
import { FileUploader } from "react-drag-drop-files";
import closeImg from '../assets/close.svg';
import { useNavigate } from "react-router-dom";
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import { ref as refDb, set, update } from "firebase/database";
import { database, storage } from "../firebase";
import "../css/FirstPage.css"
import helpIcon from '../assets/help.svg'
import emailjs from 'emailjs-com';
import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import ButtonNewCom from '@mui/material/Button';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import { Typography } from '@mui/material';
import "./Bfutton.css";

const SERVICE_ID = "service_ahfduhi";
const TEMPLATE_ID = "template_mzi14pb";
const PUBLIC_KEY = "1nt9anrE5pKavGRCG";

function WebApplicationPage() {
    const [open3, setOpen3] = React.useState(false);
    const [nextButtonText,setNextButtonText]=useState("Finish");
    useEffect(()=>{
        const componentSelected = JSON.parse(sessionStorage.getItem('componentdetails')).component;
        if (componentSelected === 'Complete System') {       
                const permanentdetails_1 = JSON.parse(sessionStorage.getItem('permanentdetails'));
                if((permanentdetails_1.numberOfIotDevices) - 1 !== 0){
                    setNextButtonText('Next');
                }
        }
    },[]);

    const handleClickOpen3 = () => {
        setOpen3(true);
    };

    const handleProceed = () => {
        setOpen3(false);
        setTimeout(() => {
            sessionStorage.clear();
            window.location.href = 'https://iot-system-design-tool.protonest.co/';
        }, 100);
    };

    const handleCancelAlert = () => {
        setOpen3(false);
    };

    const handleBackClick = () => {
        navigate(-1); // This navigates back to the previous page
    };

    useEffect(() => {
        sessionStorage.setItem('startedComponentSelected', 'started')
    }, [])
    useEffect(() => {
        sessionStorage.setItem('uiselected', 'web');
    }, []);

    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setTimeout(() => {
            sessionStorage.clear();
            window.location.href = 'https://iot-system-design-tool.protonest.co/';
        }, 100);
    };

    const [open2, setOpen2] = React.useState(false);

    const handleClickOpen2 = () => {
        setOpen2(true);
    };

    const handleClose2 = () => {
        setOpen2(false);
    };


    const [changesAdded, setChangesAdded] = useState(false);
    const fileTypes = ['ZIP', 'RAR', 'PNG', 'JPG', 'JPEG', 'GIF', 'PDF', 'DOC', 'DOCX'];
    const [fileSelected, setFileSelected] = useState(null);
    const [uploadProgress, setUploadProgress] = useState(0); // New state for upload progress
    const [isUploading, setIsUploading] = useState(false);
    const uploadTaskRef = useRef(null);
    const navigate = useNavigate();

    const sendEmail = (detailsSaved) => {
        const emailParams = {
            to_email: 'webquestionnaire108@gmail.com',
            finalIotObject: JSON.stringify(detailsSaved, null, 2)// Convert object to a readable string format
        };

        emailjs.send(
            SERVICE_ID,      // Replace with your EmailJS service ID
            TEMPLATE_ID,     // Replace with your EmailJS template ID
            emailParams,            // Parameters to send (like finalIotObject)
            PUBLIC_KEY          // Replace with your EmailJS user ID
        )
            .then((response) => {
                console.log('Email sent successfully!', response.status, response.text);
            })
            .catch((error) => {
                console.error('Failed to send email:', error);
            });
    };

    useEffect(() => {
        const handleBeforeUnload = (event) => {
            // Clear sessionStorage when the user leaves the site
            sessionStorage.clear();
        };

        window.addEventListener('beforeunload', handleBeforeUnload);

        // Cleanup event listener on component unmount
        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, [changesAdded]);


    useEffect(() => {
        const savedDetails = sessionStorage.getItem('personaldetails');
        if (!savedDetails) {
            sessionStorage.clear();
            navigate('/');
        }
    }, []);

    const handleCancelClick = () => {
        handleClickOpen3();
    };


    const upload = () => {

        if (fileSelected) {
            const dateStoring = Date.now().toString();
            const storageRef = ref(storage, `${fileSelected.name} ${dateStoring}`);
            const uploadTask = uploadBytesResumable(storageRef, fileSelected, { contentType: "application/zip" });
            uploadTaskRef.current = uploadTask;

            // Monitor the upload progress
            uploadTask.on(
                'state_changed',
                (snapshot) => {
                    const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                    setUploadProgress(progress);
                    setIsUploading(true); // Set uploading status to true
                },
                (error) => {
                    if (error.code === 'storage/canceled') {
                        console.log("Upload canceled by the user.");
                    } else {
                        console.error("Error uploading file: ", error);
                    }
                },
                () => {
                    // Upload completed successfully, now get the download URL
                    getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                        console.log(selectedOption);
                        const componentSelected = JSON.parse(sessionStorage.getItem('componentdetails')).component;
                        const personDetailsSaved = JSON.parse(sessionStorage.getItem('personaldetails'));


                        if (componentSelected === 'Complete System') {

                            const iot1 = JSON.parse(sessionStorage.getItem('iotdetails_1'));
                            const iot2 = JSON.parse(sessionStorage.getItem('savedprotocoldetails'));
                            const iot3 = (sessionStorage.getItem('storagedetails')) ? JSON.parse(sessionStorage.getItem('storagedetails')) : "Same storage for all";
                            const iot4 = JSON.parse(sessionStorage.getItem('iotchoices_1'));
                            const iot5 = (sessionStorage.getItem('iotchoices_2')) ? JSON.parse(sessionStorage.getItem('iotchoices_2')) : "Same interface settings for all";
                            const iot6 = JSON.parse(sessionStorage.getItem('iototherdetails'));
                            let iot7 = (sessionStorage.getItem('batterydetails'));
                            let iot8 = (sessionStorage.getItem('enclosuredesigndetails'));
                            let iot9 = (sessionStorage.getItem('pcbdetailssaved'));

                            let finalIotObject = {
                                iot_page_1: iot1,
                                iot_page_2: iot2,
                                iot_page_3: iot3,
                                iot_page_4: iot4,
                                iot_page_5: iot5,
                                ML_Energyconservation_Battery: iot6,
                                interfaces: downloadURL,
                                web_app_details: selectedOption
                            };

                            if (iot7) {
                                iot7 = JSON.parse(iot7)
                                finalIotObject = {
                                    ...finalIotObject,
                                    battery_details: iot7
                                };
                            }

                            if (iot8) {
                                iot8 = JSON.parse(iot8)
                                finalIotObject = {
                                    ...finalIotObject,
                                    enclosure_details: iot8
                                };
                            }

                            if (iot9) {
                                iot9 = JSON.parse(iot9)
                                finalIotObject = {
                                    ...finalIotObject,
                                    pcb_details: iot9
                                };
                            }



                            const permanentdetails = JSON.parse(sessionStorage.getItem('permanentdetails'));
                            const numberOfIotDevicesTotal = JSON.parse(sessionStorage.getItem('overviewcompletedetails')).numberOfIotDevices;
                            const iotDeviceKeyName = `iot_device_${permanentdetails.numberOfIotDevices}`
                            let detailsSaved = {};

                            let dateNow = 'date';
                            if (permanentdetails.numberOfIotDevices === numberOfIotDevicesTotal) {
                                dateNow = Date.now().toString();

                                sessionStorage.setItem('permanentdetails', JSON.stringify(
                                    {
                                        ...permanentdetails,
                                        dateNow: dateNow
                                    }
                                ));

                                detailsSaved = {
                                    personalDetails: personDetailsSaved,
                                    component: componentSelected,
                                    [iotDeviceKeyName]: finalIotObject
                                }
                                const permanentdetails2 = JSON.parse(sessionStorage.getItem('permanentdetails'));
                                const dbRef = refDb(database, `${dateNow}`);
                                set(dbRef, detailsSaved).then(() => {
                                    console.log("Saved to database");
                                    if (numberOfIotDevicesTotal === '1' || permanentdetails.numberOfIotDevices === '1') {
                                        sendEmail(detailsSaved);
                                        sessionStorage.clear();

                                        setChangesAdded(true);

                                        handleClickOpen();

                                    } else {


                                        sessionStorage.setItem('permanentdetails',
                                            JSON.stringify(
                                                {
                                                    ...permanentdetails2,
                                                    numberOfIotDevices: (permanentdetails.numberOfIotDevices) - 1,
                                                    iotname: (permanentdetails.iotname) + 1
                                                }
                                            )
                                        );

                                        sessionStorage.removeItem('iotdetails_1');
                                        sessionStorage.removeItem('savedprotocoldetails');
                                        sessionStorage.removeItem('storagedetails');
                                        sessionStorage.removeItem('iotchoices_1');
                                        sessionStorage.removeItem('iotchoices_2');
                                        sessionStorage.removeItem('iototherdetails');
                                        sessionStorage.removeItem('batterydetails');
                                        sessionStorage.removeItem('enclosuredesigndetails');
                                        sessionStorage.removeItem('pcbdetailssaved');
                                        sessionStorage.removeItem('webapppage');
                                        sessionStorage.removeItem('uiselected');
                                        navigate('/iotdevicesection_1');
                                    }

                                }).catch((error) => {
                                    console.error("Error saving in database: ", error);
                                })
                            } else {
                                dateNow = permanentdetails.dateNow;

                                detailsSaved = {
                                    [iotDeviceKeyName]: finalIotObject
                                }
                                const permanentdetails2 = JSON.parse(sessionStorage.getItem('permanentdetails'));
                                const dbRef = refDb(database, `${dateNow}`);
                                update(dbRef, detailsSaved).then(() => {
                                    console.log("Saved to database");
                                    if (numberOfIotDevicesTotal === 1 || permanentdetails.numberOfIotDevices === 1) {
                                        sendEmail(detailsSaved);
                                        sessionStorage.clear();
                                        setChangesAdded(true);
                                        //alert("Form submitted successfully");
                                        handleClickOpen();
                                        // setTimeout(() => {
                                        //     sessionStorage.clear();
                                        //     window.location.href = 'https://protonest.co/';
                                        // }, 100);
                                    } else {


                                        sessionStorage.setItem('permanentdetails',
                                            JSON.stringify(
                                                {
                                                    ...permanentdetails2,
                                                    numberOfIotDevices: (permanentdetails.numberOfIotDevices) - 1,
                                                    iotname: (permanentdetails.iotname) + 1
                                                }
                                            )
                                        );

                                        sessionStorage.removeItem('iotdetails_1');
                                        sessionStorage.removeItem('savedprotocoldetails');
                                        sessionStorage.removeItem('storagedetails');
                                        sessionStorage.removeItem('iotchoices_1');
                                        sessionStorage.removeItem('iotchoices_2');
                                        sessionStorage.removeItem('iototherdetails');
                                        sessionStorage.removeItem('batterydetails');
                                        sessionStorage.removeItem('enclosuredesigndetails');
                                        sessionStorage.removeItem('pcbdetailssaved');
                                        sessionStorage.removeItem('webapppage');
                                        sessionStorage.removeItem('uiselected');
                                        navigate('/iotdevicesection_1');
                                    }

                                }).catch((error) => {
                                    console.error("Error saving in database: ", error);
                                })
                            }


                        } else if (componentSelected === 'IOT device') {
                            const iot1 = JSON.parse(sessionStorage.getItem('iotdetails_1'));
                            const iot2 = JSON.parse(sessionStorage.getItem('savedprotocoldetails'));
                            const iot3 = JSON.parse(sessionStorage.getItem('storagedetails'));
                            const iot4 = JSON.parse(sessionStorage.getItem('iotchoices_1'));
                            const iot5 = JSON.parse(sessionStorage.getItem('iotchoices_2'));
                            const iot6 = JSON.parse(sessionStorage.getItem('iototherdetails'));
                            let iot7 = (sessionStorage.getItem('batterydetails'));
                            let iot8 = (sessionStorage.getItem('enclosuredesigndetails'));
                            let iot9 = (sessionStorage.getItem('pcbdetailssaved'));
                            let finalIotObject = {
                                iot_page_1: iot1,
                                iot_page_2: iot2,
                                iot_page_3: iot3,
                                iot_page_4: iot4,
                                iot_page_5: iot5,
                                ML_Energyconservation_Battery: iot6,
                                web_app_details: selectedOption
                            };

                            if (iot7) {
                                iot7 = JSON.parse(iot7)
                                finalIotObject = {
                                    ...finalIotObject,
                                    battery_details: iot7
                                };
                            }

                            if (iot8) {
                                iot8 = JSON.parse(iot8)
                                finalIotObject = {
                                    ...finalIotObject,
                                    enclosure_details: iot8
                                };
                            }

                            if (iot9) {
                                iot9 = JSON.parse(iot9)
                                finalIotObject = {
                                    ...finalIotObject,
                                    pcb_details: iot9
                                };
                            }

                            const detailsSaved = {
                                personalDetails: personDetailsSaved,
                                component: componentSelected,
                                interfaces: downloadURL,
                                details: finalIotObject
                            }

                            const dbRef = refDb(database, `${Date.now()}`);

                            set(dbRef, detailsSaved).then(() => {
                                console.log("Saved to database");
                            }).catch((error) => {
                                console.error("Error saving in database: ", error);
                            })
                            sendEmail(detailsSaved);
                            sessionStorage.clear();
                            setChangesAdded(true);
                            //alert("Form submitted successfully");
                            handleClickOpen();
                            // setTimeout(() => {
                            //     sessionStorage.clear();
                            //     window.location.href = 'https://protonest.co/';
                            // }, 100);

                        } else if (componentSelected === 'Web dashboard') {

                            const detailsSaved = {
                                personal_Details: personDetailsSaved,
                                component: componentSelected,
                                interfaces_zip: downloadURL,
                                details_about_dashboard: selectedOption
                            }
                            const dbRef = refDb(database, `${Date.now()}`);
                            set(dbRef, detailsSaved).then(() => {
                                console.log("Saved to database");
                            }).catch((error) => {
                                console.error("Error saving in database: ", error);
                            })
                            sendEmail(detailsSaved);
                            sessionStorage.clear();
                            setChangesAdded(true);
                            //alert("Form submitted successfully");
                            handleClickOpen();
                            // setTimeout(() => {
                            //     sessionStorage.clear();
                            //     window.location.href = 'https://protonest.co/';
                            // }, 100);

                        }
                    });
                }
            );
        } else {
            handleClickOpen2();
        }
    };



    const handleChange = (fileNew) => {
        if (fileSelected === null) {
            setFileSelected(fileNew);
        } else {
            alert('one file is already selected. Please remove that file to add new file.');
        }

    };

    const removeFile = () => {
        if (uploadTaskRef.current) {
            uploadTaskRef.current.cancel();
        }
        setFileSelected(null);
        setUploadProgress(0); // Reset progress on file removal
        setIsUploading(false); // Reset uploading status
    };

    const [selectedOption, setSelectedOption] = useState(
        () => {
            const savedDetails = sessionStorage.getItem('webapppage');
            return savedDetails ? JSON.parse(savedDetails) :
                {
                    framework: '',
                    hosting_need: 'Yes',
                    number_of_screen: '',
                    details: ''
                }
        }
    );

    useEffect(() => {
        sessionStorage.setItem('webapppage', JSON.stringify(selectedOption))
    }, [selectedOption]);

    function handleRadioChange(event) {
        const { name, value } = event.target;
        setSelectedOption((prev) => ({ ...prev, [name]: value }));
    }

    function handleClickNext() {
        if (isUploading === false) {
            upload();
        }
    }

    return (
        <Container style={{ marginBottom: "0" }}>
            <NavBar pageNo={6} />
            <div className="first-page-row" style={{ backgroundColor: "#ffffff" }}>
                <Row
                    className="first-row"
                    style={{
                        paddingTop: '20px',
                        paddingLeft: "2rem",
                        paddingRight: "2rem",
                        backgroundColor: "#ffffff",
                    }}
                >
                    <Col sm={12} md={6} >
                        <Row style={{ width: '100%', margin: '0 0 0 0', padding: '0 0 0 0' }}>
                            <div style={{ width: '100%', }}>
                                <p className="heading" style={{ fontFamily: 'poppins', fontWeight: '700', color: "rgba(71, 85, 105, 1)" }}>Web Application</p>
                            </div>


                            {(sessionStorage.getItem("componentdetails")) && JSON.parse(sessionStorage.getItem("componentdetails")).component === "Web dashboard" &&
                                <div style={{ marginTop: '0.5rem', marginBottom: '0.3rem' }} >
                                    <label
                                        className="text"
                                        style={{
                                            fontWeight: '500',
                                            marginBottom: "0.5rem",
                                            fontFamily: "poppins",
                                            color: "rgba(71, 85, 105, 1)",
                                        }}
                                    >
                                        Please list down be the functionalities of the Web Application?
                                    </label>
                                    <br />
                                    <textarea
                                        value={selectedOption.details}
                                        type="text"
                                        name="details"
                                        placeholder="Enter features"
                                        className="textBox10"
                                        style={{
                                            border: '1px solid rgba(203, 213, 225, 0.7)',
                                            fontFamily: 'poppins',
                                            padding: '3px 3px 3px 15px',
                                            marginTop: "0.3rem",
                                            maxLines: '40',
                                            borderRadius: "5px",
                                            width: "93%",
                                            resize: 'none'
                                        }}
                                        onChange={(e) => (setSelectedOption((prev) => { return { ...prev, details: e.target.value } }))}
                                    />
                                </div>
                            }

                            <div style={{ marginBottom: '10px', marginTop: '10px' }} >
                                <label className="text" style={{ fontFamily: 'poppins', color: "rgba(71, 85, 105, 1)" }}>
                                    What framework needs to be used in making the web dashboard?
                                    <span ><a href="https://protonest.co/" target="_blank" rel="noopener noreferrer"><img src={helpIcon} alt="help" style={{
                                        paddingLeft: '3px', width: '1rem',
                                        cursor: 'pointer', verticalAlign: 'middle'
                                    }} /></a></span>
                                </label>
                                <div style={{ marginTop: '15px', display: 'flex', alignItems: 'center', justifyContent: 'center', paddingLeft: '30px' }}>
                                    {["React.js", "Angular", "Vue.js"].map((framework) => (
                                        <label className="text" key={framework} style={{ fontFamily: 'poppins', paddingRight: '20px', color: "rgba(71, 85, 105, 1)", display: 'flex', alignItems: 'center' }}>
                                            <input
                                                type="radio"
                                                value={framework}
                                                name="framework"
                                                checked={selectedOption.framework === framework}
                                                style={{
                                                    cursor: 'pointer'
                                                }}
                                                onChange={handleRadioChange}
                                            />
                                            {framework}
                                        </label>
                                    ))}
                                </div>
                            </div>
                            <div >
                                <label className="text" style={{ fontFamily: 'poppins', color: "rgba(71, 85, 105, 1)" }}>
                                    Do you need to host the web dashboard in a cloud platform?
                                </label>
                                <div style={{ marginTop: '15px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                    {["Yes", "No"].map((hosting_need) => (
                                        <label className="text" key={hosting_need} style={{ fontFamily: 'poppins', paddingRight: '20px', color: "rgba(71, 85, 105, 1)", display: 'flex', alignItems: 'center' }}>
                                            <input
                                                type="radio"
                                                value={hosting_need}
                                                name="hosting_need"
                                                checked={selectedOption.hosting_need === hosting_need}
                                                style={{ cursor: 'pointer' }}
                                                onChange={handleRadioChange}
                                            />
                                            {hosting_need}
                                        </label>
                                    ))}
                                </div>
                            </div>
                            <div style={{ marginTop: '5px' }}>
                                <label
                                    className="text"
                                    style={{
                                        fontWeight: '500',
                                        marginBottom: "0.5rem",
                                        fontFamily: "poppins",
                                        color: "rgba(71, 85, 105, 1)",
                                        paddingRight: "20px"
                                    }}
                                >
                                    Number of screens on this web application :
                                </label>

                                <input
                                    className="inputBox"
                                    type="text"
                                    name="numberOfPages"
                                    placeholder="Number"
                                    value={selectedOption.number_of_screen}
                                    style={{
                                        border: '1px solid rgba(203, 213, 225, 0.7)',
                                        fontFamily: 'poppins',
                                        padding: '3px 3px 3px 15px',
                                        marginTop: "0.5rem",
                                        borderRadius: "5px",
                                        width: "20%",
                                    }}
                                    onChange={(e) => {
                                        setSelectedOption((prevState) => ({ ...prevState, number_of_screen: e.target.value, }));
                                    }}
                                />
                            </div>
                        </Row>
                    </Col>
                    <Col sm={12} md={6} >
                        <Row className="margin-md" >
                            <div >
                                <p className="text" style={{
                                    fontFamily: 'poppins', fontWeight: '500',
                                    color: 'rgba(71, 85, 105, 1)',

                                }}>
                                    Please provide the hand-drawn sketches<span style={{ color: 'red' }}> *</span>
                                </p>
                            </div>
                            < div
                                style={{
                                    border: '1px dashed rgba(0, 0, 0, 1)',
                                    width: '100%',
                                    margin: '0 0 20px 0',
                                    borderRadius: '6px',
                                    height: '10rem',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    position: 'relative',
                                }}
                            >
                                <Col sm={12} md={12} style={{ height: '100%', margin: '0 0 0 0', padding: '0 0 0 0' }}>
                                    <FileUploader
                                        handleChange={handleChange}
                                        name="file"
                                        types={fileTypes}
                                    >
                                        <div style={{
                                            width: '100%',
                                            height: '100%',
                                            cursor: 'pointer',
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            flexDirection: 'column',
                                        }} >
                                            <p className="text" style={{ fontFamily: 'poppins', fontWeight: '500', color: 'rgba(71, 85, 105, 1)' }}>
                                            Drop file here (jpg/png/pdf/docx/zip)
                                            </p>
                                            <img src={uploadSvg} alt="upload" />
                                        </div>

                                    </FileUploader>
                                </Col>

                            </div>
                        </Row>
                        <Row >
                            <Col sm={12} md={12} style={{ height: '100%', margin: '0' }}>
                                <div style={{ width: '100%', paddingTop: '10px', display: 'flex' }}>

                                    {(fileSelected !== null &&
                                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', paddingRight: '15px', paddingBottom: '20px' }}>
                                            <img
                                                className="imageWidth"
                                                src={closeImg}
                                                alt="remove file"

                                                style={{ cursor: 'pointer', paddingBottom: '5px' }}
                                                onClick={removeFile}
                                            />
                                            <img className="imageWidth" src={fileImg} alt="file icon" style={{ margin: "0 0 0 0", paddingBottom: '5px' }} />
                                            <p className="text" style={{ fontFamily: 'poppins', fontWeight: '500', color: 'rgba(71, 85, 105, 1)', margin: "0 0 0 0" }}>{fileSelected.name}</p>
                                        </div>
                                    )}

                                </div>
                            </Col>
                        </Row>
                        <Row >
                            <Col sm={12} md={12} style={{ height: '100%', margin: '0' }}>
                                {isUploading && (
                                    <div style={{ marginBottom: '1rem' }}>
                                        <p className="text" style={{ fontFamily: 'poppins', color: 'rgba(71, 85, 105, 1)' }}>
                                            Uploading: {uploadProgress.toFixed(2)}%
                                        </p>
                                        <div style={{
                                            backgroundColor: 'rgba(71, 85, 105, 0.3)',
                                            height: '10px',
                                            borderRadius: '5px',
                                            width: '100%',
                                        }}>
                                            <div style={{
                                                backgroundColor: '#3b82f6',
                                                height: '100%',
                                                borderRadius: '5px',
                                                width: `${uploadProgress}%`,
                                                transition: 'width 0.2s ease'
                                            }}></div>
                                        </div>
                                    </div>
                                )}

                            </Col>
                        </Row>
                    </Col>
                </Row>
            </div>
            <br />
            <div>
                <Row>
                    <Col sm={12} md={12} className="button-container">
                    <Button className="text" onClick={handleCancelClick} style={{backgroundColor: 'rgba(71, 85, 105, 1)', borderRadius: '6px', color: 'white', minHeight: '40px', minWidth: '100px', marginRight: '20px', fontFamily: 'poppins', fontWeight: 'bold'}} > Cancel </Button>
                        <Button onClick={handleBackClick} style={{ backgroundColor: 'rgba(71, 85, 105, 1)', borderRadius: '6px', color: 'white', minHeight: '40px', minWidth: '100px', marginRight: '20px', fontFamily: 'poppins', fontWeight: 'bold', fontSize: '13px' }}> Back </Button>
                        <Button onClick={handleClickNext} style={{ backgroundColor: 'rgba(156, 136, 255, 1)', borderRadius: '6px', color: 'white', minHeight: '40px', minWidth: '100px', fontFamily: 'poppins', fontWeight: 'bold', fontSize: '13px' }}> {nextButtonText} </Button>
                    </Col>
                </Row>
            </div>

            <Dialog
                open={open2}
                onClose={handleClose2}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                maxWidth="sm"
                fullWidth
                PaperProps={{
                    style: {
                        borderRadius: '16px',
                        padding: '0.5rem',
                        background: 'linear-gradient(135deg, #9C88FF, #b3a5fa)',
                        color: 'white',
                    }
                }}
            >
                <DialogTitle id="alert-dialog-title" style={{ display: 'flex', alignItems: 'center' }}>
                    <WarningAmberIcon style={{ marginRight: '0.5rem', color: 'white' }} />
                    <Typography variant="h6" component="span" style={{ color: '#fff9c4' }}>
                        Alert
                    </Typography>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText
                        id="alert-dialog-description"
                        style={{
                            color: 'white',
                            fontSize: '1.1rem',
                            fontFamily: 'poppins',
                            marginBottom: '1rem',
                        }}
                    >
                        Please upload file to continue.
                    </DialogContentText>
                </DialogContent>
                <DialogActions style={{ justifyContent: 'center' }}>
                    <ButtonNewCom
                        onClick={handleClose2}
                        variant="contained"
                        style={{
                            backgroundColor: 'white',
                            color: '#9C88FF',
                            borderRadius: '16px',
                            padding: '0.5rem 1.5rem',
                            fontWeight: 'bold',
                        }}
                    >
                        Close
                    </ButtonNewCom>
                </DialogActions>
            </Dialog>

            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                maxWidth="sm"
                fullWidth
                PaperProps={{
                    style: {
                        borderRadius: '16px',
                        padding: '0.5rem',
                        background: 'linear-gradient(135deg, #9C88FF, #b3a5fa)',
                        color: 'white',
                    }
                }}
            >
                <DialogTitle id="alert-dialog-title" style={{ display: 'flex', alignItems: 'center' }}>

                    <Typography variant="h6" component="span" style={{ color: '#fff9c4' }}>
                        Success
                    </Typography>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText
                        id="alert-dialog-description"
                        style={{
                            color: 'white',
                            fontSize: '1.1rem',
                            fontFamily: 'poppins',
                            marginBottom: '1rem',
                        }}
                    >
                        Your form has been successfully submitted.
                        The Protonest team will reach out to you soon for further details.
                    </DialogContentText>
                </DialogContent>
                <DialogActions style={{ justifyContent: 'center' }}>
                    <ButtonNewCom
                        onClick={handleClose}
                        variant="contained"
                        style={{
                            backgroundColor: 'white',
                            color: '#9C88FF',
                            borderRadius: '16px',
                            padding: '0.5rem 1.5rem',
                            fontWeight: 'bold',
                        }}
                    >
                        Close
                    </ButtonNewCom>
                </DialogActions>
            </Dialog>
            <Dialog
                open={open3}
                onClose={handleCancelAlert}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                maxWidth="sm"
                fullWidth
                PaperProps={{
                    style: {
                        borderRadius: '16px',
                        padding: '0.5rem',
                        background: 'linear-gradient(135deg, #9C88FF, #b3a5fa)',
                        color: 'white',
                    }
                }}
            >
                <DialogTitle id="alert-dialog-title" style={{ display: 'flex', alignItems: 'center' }}>

                    <Typography variant="h6" component="span" style={{ color: '#fff9c4' }}>
                        Confirm
                    </Typography>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText
                        id="alert-dialog-description"
                        style={{
                            color: 'white',
                            fontSize: '1.1rem',
                            fontFamily: 'poppins',
                            marginBottom: '1rem',
                        }}
                    >
                        Are you sure you want to cancel? All your data will be removed if you proceed.
                    </DialogContentText>
                </DialogContent>
                <DialogActions style={{ justifyContent: 'center' }}>
                    <ButtonNewCom
                        onClick={handleCancelAlert}
                        variant="contained"
                        style={{
                            backgroundColor: 'white',
                            color: '#9C88FF',
                            borderRadius: '16px',
                            padding: '0.5rem 1.5rem',
                            fontWeight: 'bold',
                            marginRight: '10px', // add some space between buttons
                        }}
                    >
                        Cancel
                    </ButtonNewCom>
                    <ButtonNewCom
                        onClick={handleProceed}
                        variant="contained"
                        style={{
                            backgroundColor: 'white',
                            color: '#9C88FF',
                            borderRadius: '16px',
                            padding: '0.5rem 1.5rem',
                            fontWeight: 'bold',
                        }}
                    >
                        Proceed
                    </ButtonNewCom>
                </DialogActions>
            </Dialog>
        </Container>
    );
}

export default WebApplicationPage;